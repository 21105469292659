<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button
            href="/c77e8ed8fe66bb945c424fecdfcc71b7aa447a5080f6c66b350b5b63da373b8570c97754921ea135283b238dc572ee18946f569bb49f4cb7e6f834ddc8cf4e41/routes"
            tab="routes"
        >
          <ion-icon name="analytics"/>
          <ion-label>Маршруты</ion-label>
        </ion-tab-button>
        <ion-tab-button
            href="/c77e8ed8fe66bb945c424fecdfcc71b7aa447a5080f6c66b350b5b63da373b8570c97754921ea135283b238dc572ee18946f569bb49f4cb7e6f834ddc8cf4e41/comments"
            tab="comments"
        >
          <ion-icon name="chatbubbles"/>
          <ion-label>Комментарии</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonTabs,
  IonPage,
  IonRouterOutlet,
} from '@ionic/vue';
import {
  square,
} from 'ionicons/icons';

export default {
  name:       "MainCommentsLayout",
  components: { IonTabs, IonPage, IonRouterOutlet },
  setup() {
    return {
      square,
    };
  },
  methods: {},
};

</script>

<style scoped>

</style>
